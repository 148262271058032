import React from 'react';

export default class Post extends React.Component {

  render() {
    return (
      <div>
        post
      </div>
    )
  }
}